import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screen31 } from '../../../components/images/support/certifications/Screen31.generated';
import { Screen32 } from '../../../components/images/support/certifications/Screen32.generated';
import { Screen33 } from '../../../components/images/support/certifications/Screen33.generated';
import { Screen34 } from '../../../components/images/support/certifications/Screen34.generated';
import { Screen35 } from '../../../components/images/support/certifications/Screen35.generated';
import { Screen36 } from '../../../components/images/support/certifications/Screen36.generated';
import { Screen37 } from '../../../components/images/support/certifications/Screen37.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "approving-certifications-️"
    }}>{`Approving Certifications ☑️`}</h1>
    <p>{`When employees upload a Certification we need to verify that the image uploaded is valid and the details are correct.`}</p>
    <p>{`Canyou gives you the ability to check each uploaded Certification and either approve or reject them.`}</p>
    <p>{`In this example we will demonstrate how to approve or reject a Certification`}</p>
    <h3 {...{
      "id": "lets-approve-a-certification"
    }}>{`Let's approve a Certification`}</h3>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the Users menu. `}</li>
    </ol>

    <Screen31 mdxType="Screen31" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click the user with the Certification we want to review.`}</li>
    </ol>

    <Screen32 mdxType="Screen32" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`We can see here that our employee has a certification with a "Submitted" status. We need to review this Certification and either approve or reject.
Click the Certification.`}</li>
    </ol>

    <Screen33 mdxType="Screen33" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Now we can review the details of the certification to ensure it is correct. We can click on the image to show the certification full size `}</li>
    </ol>
    <p>{`(Tip: if you want to download a copy of a Certification you can right click and "Save As").`}</p>

    <Screen34 mdxType="Screen34" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`If we are satisfied with the image and the details we can click the "Approve" button. Alternatively we can click the "Reject" button if we would like the employee to re-submit the Certification.`}</li>
    </ol>

    <Screen35 mdxType="Screen35" />
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`We can now see the Certification is in the "Approved" status.`}</li>
    </ol>

    <Screen36 mdxType="Screen36" />
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`We can also navigate to the Certifications menu at any time to review, approve and reject all your Certifications.`}</li>
    </ol>

    <Screen37 mdxType="Screen37" />
    <h1 {...{
      "id": "great-job-you-have-approved-your-certification-"
    }}>{`Great Job you have approved your Certification 🎉`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      